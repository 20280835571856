

<template>
  <DonutChart v-if="chartData.labels.length > 0 && chartData.series.length > 0" chartLabel="Negative Topics (Top 10)"
    :labels="chartData.labels" :series="chartData.series" />
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import DonutChart from "@/components/Charts/DonutChart.vue";

const mainStore = useMainStore();

const chartData = reactive({
  labels: [],
  series: []
});

const updateChartData = () => {
  const series = [];
  const labels = [];

  mainStore.negativeTopics.slice(0, 10).forEach(item => {
    series.push(item.review_count);
    labels.push(item.topic_label);
});

  chartData.labels = labels;

  chartData.series = series;
};

watch(() => mainStore.negativeTopics, () => {
  updateChartData();
});

updateChartData();
</script>
