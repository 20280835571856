import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { useMainStore } from "@/stores/main.js";
import { useStyleStore } from "@/stores/style.js";
import { darkModeKey, styleKey } from "@/config.js";
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";
import "./css/main.css";
import { watch } from "vue";
import PocketBase from "pocketbase";

import { BASE_API_URL } from "./config";

import * as Sentry from "@sentry/vue";


const pb = new PocketBase(BASE_API_URL);


if (import.meta.env.PROD) {
  axios.defaults.baseURL = "https://api.buzztracker.co.za/";
} else {
  axios.defaults.baseURL = "http://127.0.0.1:8090/";
}

/* Init Pinia */
const pinia = createPinia();

/* Create Vue app */

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://ce7d2ea6890d426a38b059e7faaeb8ae@o4506720916537344.ingest.sentry.io/4506720936919040",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "buzztracker.co.za", "api.buzztracker.co.za"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
app.use(pinia)
app.use(VueApexCharts)
app.mount("#app");

/* Init Pinia stores */
const styleStore = useStyleStore(pinia);
const mainStore = useMainStore(pinia);

/* App style */
styleStore.setStyle(localStorage[styleKey] ?? "basic");

/* Dark mode */
// if (
//   (!localStorage[darkModeKey] &&
//     window.matchMedia("(prefers-color-scheme: dark)").matches) ||
//   localStorage[darkModeKey] === "1"
// ) {
//   styleStore.setDarkMode(true);
// }

/* Default title tag */
const defaultDocumentTitle = "BuzzTracker";

if (pb.authStore.isValid && pb.authStore.model.enabled === false) {
  router.push("/awaiting-activation");
}




watch(
  () => mainStore.organisation,
  (value) => {
    if (value) {
      mainStore.fetchCompanies();
    }
  }
)

await mainStore.authRefresh();

/* Set document title from route meta */
router.afterEach((to) => {
  document.title = to.meta?.title
    ? `${to.meta.title} — ${defaultDocumentTitle}`
    : defaultDocumentTitle;
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = mainStore.userEmail !== null;
  const isActivated = mainStore.userActivated !== null && mainStore.userActivated;

  if (to.fullPath === "/awaiting-activation") {
    next();
  }

  const requiresAuth = to.meta.requiresAuth;

  if (requiresAuth && !isAuthenticated) {
    // Redirect to login if authentication is required but user is not authenticated
    next('/login');
  } else {
    if (isAuthenticated && !isActivated) {
      next("/awaiting-activation");
      return;
    }
    next();
  }
});
