<template>
  <div class="chart-container">
    <apexchart
      ref="chart"
      width="100%"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<style scoped>
.chart-container {
  height: 100%;
  min-height: 350px;
}
</style>

<script>
export default {
  props: {
    labels: { type: Array, required: true },
    series: { type: Array, required: true },
    chartLabel: { type: String, required: true },
  },
  data() {
    const numbers = this.series[0].data;
    const maxNumber = Math.max(...numbers);
    const minNumber = Math.min(...numbers);
    const range = maxNumber - minNumber || 1;
    const graphCutoff = Math.round((maxNumber / range) * 100);

    return {
      chartOptions: {
        chart: { type: 'line', stacked: true },
        title: { text: this.chartLabel },
        xaxis: { categories: this.labels },
        yaxis: { min: 0, max: 100 },
        colors: ['#B72136'],
      },
    };
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
  },
  methods: {
    updateChart(newLabels) {
      if (!this.$refs.chart) return;
      this.$refs.chart.updateOptions({ xaxis: { categories: newLabels } });
    },
  },
};
</script>
