<template>
  <div class="chartContainer">
    <apexchart
      height="100%"
      ref="chart"
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<style scoped>
.chartContainer {
  height: 100%;
  min-height: 350px;
}
</style>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ["#25A0FC", "#B72136"], // Custom colors
    },
  },
  data() {
    return {
      series: [
        {
          name: "Frequency",
          type: "column",
          data: this.values,
        },
        {
          name: "Cumulative %",
          type: "line",
          data: this.calculateCumulativePercentages(this.values),
          yAxisIndex: 1, // Ensures it maps to the second Y-axis
        },
      ],
      chartOptions: {
        colors: this.colors,
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false, // Use vertical bars
            columnWidth: "80%",
          },
        },
        stroke: {
          width: [0, 3], // Ensure line is visible
          curve: "monotoneCubic", // Smooth line
        },
        title: {
          text: this.chartLabel,
        },
        xaxis: {
          categories: this.labels,
        },
        yaxis: [
          {
            title: {
              text: "Frequency",
            },
          },
          {
            opposite: true,
            title: {
              text: "Cumulative %",
            },
            min: 0,
            max: 100, // Ensure the line is properly scaled
          },
        ],
        markers: {
          size: 4, // Add markers for better visibility
        },
        dataLabels: {
          enabled: true, // Default enabled (only for bar)
          enabledOnSeries: [0], // **Enable labels only for bars (index 0)**
        },
      },
    };
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
    values(newValues) {
      this.updateSeries(newValues);
    },
  },
  methods: {
    calculateCumulativePercentages(values) {
      const total = values.reduce((sum, val) => sum + val, 0);
      let cumulativeSum = 0;
      return values.map((val) => {
        cumulativeSum += val;
        return ((cumulativeSum / total) * 100).toFixed(2);
      });
    },
    updateChart(newLabels) {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels,
          },
        });
      }
    },
    updateSeries(newValues) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries([
          {
            name: "Frequency",
            type: "column",
            data: newValues,
          },
          {
            name: "Cumulative %",
            type: "line",
            data: this.calculateCumulativePercentages(newValues),
            yAxisIndex: 1, // Ensures the line chart uses the second Y-axis
          },
        ]);
      }
    },
  },
};
</script>
