<template>
  <LineChart
    v-if="chartData.dates.length > 0 && chartData.series.length > 0"
    chartLabel="Negative Review %"
    :labels="chartData.dates"
    :series="chartData.series"
  />
</template>

<script setup>
import { reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import LineChart from './LineChart.vue';
import { mapPeriodToDateRange } from '@/utils';

const mainStore = useMainStore();

const chartData = reactive({
  dates: [],
  series: []
});

const updateChartData = () => {
  const { startDate, endDate } = mapPeriodToDateRange(mainStore.selectedPeriod);

    const dateArray = [];
    const dailyPercentageArray = [];

    mainStore.negativeReviewStats.forEach(item => {
      dateArray.push(item.date);
      const percentage = item.total_count > 0 ? Math.round((item.negative_count / item.total_count) * 100) : 0;
      dailyPercentageArray.push(percentage);
    });

    chartData.dates = dateArray;
    chartData.series = [{
      name: 'Negative Review %',
      data: dailyPercentageArray
    }];

};

watch(() => mainStore.sentimentOverTime, updateChartData);

updateChartData();
</script>
