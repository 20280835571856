<template>
  <dropdown :text="selectedText">
    <list-group>
      <list-group-item class="border-none" v-for="(item, index) in items" :key="index" @click="selectItem(item)">
        {{ item }}
      </list-group-item>
    </list-group>
  </dropdown>
</template>

<script>
import { Dropdown, ListGroup, ListGroupItem } from "flowbite-vue";
import { ref } from 'vue';
import { useMainStore } from "@/stores/main";


export default {
  components: {
    // Ensure the imported components are registered
    Dropdown,
    ListGroup,
    ListGroupItem
  },
  props: {
    text: {
      type: String,
      default: 'Monthly'
    }
  },
  setup(props) {
    const mainStore = useMainStore();
    const isOpen = ref(false);
    const selectedText = ref(props.text);
    const items = ref([
      'Daily',
      'Weekly',
      'Monthly',
      'Quarterly',
      'Yearly',
    ]);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectItem = (item) => {
      selectedText.value = item;
      isOpen.value = false;
      // Emit an event or perform any action with the selected item
      mainStore.setAggregation(item);
    };

    return {
      isOpen,
      selectedText,
      items,
      toggleDropdown,
      selectItem
    };
  }
};
</script>

<style>
/* Add your custom styling here */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  list-style: none;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu.show {
  display: block;
}
</style>
